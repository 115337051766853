module.exports = [
    {
      "id": "to-be",
      "title": "To Be Present",
      "category": "Grammar",
      "level": "A1",
      "time": "15 min",
      "description": "Exercises to practice the verb 'to be' in the present",
      "image": "/Images/argue1.jpg"
    },
    {
      "id": "to-be-past",
      "title": "To Be Past",
      "category": "Grammar",
      "level": "A1",
      "time": "45 min",
      "description": "Explore branding strategies while practicing dependent prepositions.",
      "image": "/Images/argue1.jpg"
    },
    {
      "id": "simple-present",
      "title": "Simple Present",
      "category": "Grammar",
      "level": "A1",
      "time": "30 min",
      "description": "A discussion on the role of cell phones in modern communication.",
      "image": "/Images/argue1.jpg"
    },
    {
      "id": "present-continuous",
      "title": "Present Continuous",
      "category": "Grammar",
      "level": "A2",
      "time": "60 min",
      "description": "Discuss what motivates people financially and explore related vocabulary.",
      "image": "/Images/argue3.jpg"
    },
    {
      "id": "meetings",
      "title": "Meetings - Modals of Deduction",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Improve your ability to deduce meaning in professional meetings.",
      "image": "/images/argue4.webp"
    },
    {
      "id": "workfuture",
      "title": "Work Future - Future Forms",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss future trends in work and practice future tenses.",
      "image": "/images/watchingsports3.jpg"
    },
    {
      "id": "fastfood",
      "title": "Fast Food - ###",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Explore the fast food industry and its impact on society.",
      "image": "/images/watchingsports4.jpg"
    },
    {
      "id": "caffeine",
      "title": "Coffee - ###",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss coffee culture and the effects of caffeine.",
      "image": "/images/watchingsports5.png"
    },
    {
      "id": "newyearsresolution",
      "title": "New Year's Resolutions - Future Plans",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Set and discuss New Year's resolutions while practicing future plans.",
      "image": "/images/newyearsresolution.jpg"
    },
    {
      "id": "habits",
      "title": "Habits - Expressing Habits",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Learn how to talk about personal and professional habits.",
      "image": "/images/habits.jpg"
    },
    {
      "id": "chatplatforms",
      "title": "Chat Platforms - So and Enough",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss chat platforms while learning how to use 'so' and 'enough'.",
      "image": "/images/chatplatforms.jpg"
    },
    {
      "id": "automation",
      "title": "Automation - Cause and Effect",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Explore automation trends and practice cause-and-effect structures.",
      "image": "/images/automation.jpg"
    },
    {
      "id": "quality",
      "title": "Quality - Comparison",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Compare quality standards in various industries.",
      "image": "/images/quality.jpg"
    },
    {
      "id": "choice",
      "title": "Choice - Wishes and Regrets",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss choices in business and express wishes and regrets.",
      "image": "/images/choice.jpg"
    },
    {
      "id": "pets",
      "title": "Pets - So and Enough",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Learn about pet ownership and practice 'so' and 'enough'.",
      "image": "/images/pets.jpg"
    },
    {
      "id": "walking",
      "title": "Walking - Gerunds",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Talk about walking and its benefits while practicing gerunds.",
      "image": "/images/walking.jpg"
    },
    {
      "id": "people",
      "title": "People - Relative Clauses",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Describe people using relative clauses.",
      "image": "/images/people.jpg"
    },
    {
      "id": "neighbors",
      "title": "Neighbors - 2nd Conditional",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss neighborly issues while practicing the 2nd conditional.",
      "image": "/images/neighbors.jpg"
    },
    {
      "id": "food",
      "title": "Food - Passive",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Talk about food production and use the passive voice.",
      "image": "/images/food.jpg"
    },
    {
      "id": "tourism",
      "title": "Tourism - Gerunds",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss tourism and its impact while practicing gerunds.",
      "image": "/images/tourism.jpg"
    },
    {
      "id": "advice",
      "title": "Advice - Suggest",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Learn how to give and receive advice using 'suggest'.",
      "image": "/images/advice.jpg"
    },
    {
      "id": "ethics",
      "title": "Ethics - Infinitives",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Explore ethical dilemmas and practice using infinitives.",
      "image": "/images/ethics.jpg"
    },
    {
      "id": "gossip",
      "title": "Gossip - Reported Speech",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss workplace gossip while practicing reported speech.",
      "image": "/images/gossip.jpg"
    },
    {
      "id": "sustainability",
      "title": "Sustainability - Unless",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Explore sustainability practices while learning how to use 'unless'.",
      "image": "/images/sustainability.jpg"
    },
    {
      "id": "problemsolving",
      "title": "Problem Solving - Modals of Deduction",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Practice problem-solving strategies and modals of deduction.",
      "image": "/images/problemsolving.jpg"
    },
    {
      "id": "medicine",
      "title": "Medicine - No Grammar",
      "category": "Business",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss medical advancements and their ethical implications.",
      "image": "/images/medicine.jpg"
    },
    {
        "id": "persuading",
        "title": "Persuading - Word Forms",
        "category": "Business",
        "level": "B2",
        "time": "45 min",
        "description": "Learn persuasive techniques while practicing different word forms.",
        "image": "/images/persuading.jpg"
      },
      {
        "id": "sports",
        "title": "Sports - Present Perfect",
        "category": "General",
        "level": "B1",
        "time": "30 min",
        "description": "Discuss sports experiences while practicing the present perfect tense.",
        "image": "/images/sports.jpg"
      },
      {
        "id": "weather",
        "title": "Weather - Mixed Conditionals",
        "category": "General",
        "level": "B2",
        "time": "60 min",
        "description": "Explore extreme weather events while using mixed conditionals.",
        "image": "/images/weather.jpg"
      },
      {
        "id": "education",
        "title": "Education - Wish, 3rd Conditional",
        "category": "Business",
        "level": "B2",
        "time": "60 min",
        "description": "Talk about past educational experiences and express wishes and regrets.",
        "image": "/images/education.jpg"
      },
      {
        "id": "introlesson",
        "title": "Introduction - Tense Assessment",
        "category": "General",
        "level": "B1",
        "time": "45 min",
        "description": "A lesson to assess students' understanding of various tenses.",
        "image": "/images/introlesson.jpg"
      },
      {
        "id": "airtraffic",
        "title": "Air Traffic",
        "category": "Business",
        "level": "B2",
        "time": "60 min",
        "description": "Discuss the air traffic industry and aviation terminology.",
        "image": "/images/airtraffic.jpg"
      }
  ]
  